import React from 'react'

import Layout from '../../components/layoutPublic';
import ResendEmailForm from '../../components/resend/resendEmailForm';
import Page from '../../components/page';

const PublicPage = ({ location }) => {
  return (
    <Page authenticated={false}>
      <ResendEmailForm
        type="clientinfo"
        description="If you are having trouble accessing your form and would like a new access link sent to your inbox, please enter your email below."
      />
    </Page>
  )
}

export default PublicPage
